#root {
  width: 100%;
}
.main-logo {
  height: 50px;
  vertical-align: middle;
}
.home-truck-image {
  margin-top:70px;
  min-height:300px;
  min-width:100%;
  background:#000;
  filter: brightness(0.80);
}
.centered {
  color: white;
  max-width: 500px;
  text-align: left;
  margin: 0;
  position: absolute;
  top: 125px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.App {
  text-align: center;
  width: 100%;
}
body {
	padding: 0;
  margin: 0;
  background: #f0f2f5;
}
.container {
	position: relative;
  max-width: 1200px;
  background: #fff;
  margin: 70px auto 0px;
}
.containerDiv {
  vertical-align: middle;
  display: inline-block;
  text-align: left;
  width: 46%;
  padding: 2%;
}
.container-div-about {
  vertical-align: middle;
  display: inline-block;
  text-align: left;
  width: 50%;
  padding: 2%;
}
.containerDiv-services-reefer-small {
  vertical-align: middle;
  display: none;
  text-align: left;
  width: 96%;
  padding: 2%;
}
.containerDiv-services-reefer-large {
  vertical-align: middle;
  display: inline-block;
  text-align: left;
  width: 46%;
  padding: 2%;
}
.containerDiv-bordered {
  border: 1px solid gray;
  display: inline-block;
  text-align: left;
  width: 49.5%;
  max-width: 575px;
  margin:0.1%;
}
.containerDiv-bordered:hover {
  border: 1px solid rgb(24, 144, 255);
}
.footer-links {
  display: block;
  color: #fff;
  text-decoration: none;
}
.footer-links:hover {
  text-decoration: underline;
}
footer {
  text-align: center;
  margin: 0 auto;
  width: 100%;
  background: #042340;
}
footer .footerContainer {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 0px;
  color: #fff
}
footer .footerDiv {
  display: inline-block;
  width: 300px;
  padding: 10px 0px;
}
.smallScreenSeparator {
  display: none;
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}
footer .footerCopyRight {
  display: inline-block;
  width: 100%;
}
footer .footer-icon {
  margin: 0 5px;
}
footer .footer-icon:hover {
  filter: invert(42%) sepia(29%) saturate(4255%) hue-rotate(193deg) brightness(103%) contrast(102%);
}
.home-services-icon {
  display: block;
  margin: 0;
  color: #000;
  text-decoration: none;
  padding: 2%;
}
nav {
	position: fixed;
	z-index: 10;
	left: 0;
	right: 0;
	top: 0;
	font-family: 'Montserrat', sans-serif;

	height: 70px;
	background-color: #042340;
}
nav .navContainer {
  max-width: 1200px;
  height: 100%;
  margin: 0px auto;
}
nav .logo {
  text-align: center;
  line-height: 70px;
	width: 50%;
	height: 100%;
	display: inline-block;
	align-items: center;
	font-size: 24px;
	color: #fff;
}
nav .links {
	float: right;
	padding: 0;
	margin: 0;
	width: 50%;
	height: 100%;
	display: flex;
	justify-content: space-around;
	align-items: center;
}
nav .links li {
  list-style: none;
  width: 25%;
}
nav .links li:hover {
  background: #1890ff;
}
nav .links a {
  display: block;
  line-height: 70px;
	font-size: 16px;
	font-weight: bold;
	color: #fff;
	text-decoration: none;
}
#nav-toggle {
	position: absolute;
	top: -70px;
}
nav .icon-burger {
	display: none;
	position: absolute;
	right: 5%;
	top: 50%;
	transform: translateY(-50%);
}
nav .icon-burger .line {
	width: 30px;
	height: 5px;
	background-color: #fff;
	margin: 5px;
	border-radius: 3px;
	transition: all .3s ease-in-out;
}
@media screen and (max-width: 768px) {
  .home-services-icon {
    font-size: 15px;
  }
  .home-truck-image {
    min-height:0px;
    min-width:0px;
    filter: brightness(1.0);
  }
  .containerDiv-bordered {
    border: 1px solid gray;
    display: inline-block;
    text-align: left;
    width: 94.5%;
    max-width: 99%;
    margin:2px 0px;
  }
  .centered {
    color: black;
    max-width: 100%;
    width: 96%;
    position: relative;
    top: 0;
    padding: 2%;
  }
  .containerDiv {
    width: 96%;
    padding: 2%;
  }
  .container-div-about {
    width: 96%;
    padding: 2%;
  }
  .containerDiv-services-reefer-small {
    display: inline-block;
  }
  .containerDiv-services-reefer-large {
    display: none;
  }
  footer .footerDiv {
    width: 100%;
    padding: 0px;
  }
  .smallScreenSeparator {
    display: block;
  }
  nav .links li {
    width: 100%;
  }
	nav .logo {
		width: auto;
    justify-content: center;
    padding-right: 12%;
	}
	nav .links {
		float: none;
		position: fixed;
		z-index: 9;
		left: 0;
		right: 0;
		top: 70px;
		bottom: 100%;
		width: auto;
		height: auto;
		flex-direction: column;
		justify-content: space-evenly;
		background-color: rgba(0,0,0,.8);
		overflow: hidden;
		box-sizing: border-box;
		transition: all .5s ease-in-out;
	}
	nav .links a {
    font-size: 20px;
	}
	nav :checked ~ .links {
		bottom: 0;
	}
	nav .icon-burger {
		display: block;
	}
	nav :checked ~ .icon-burger .line:nth-child(1) {
		transform: translateY(10px) rotate(225deg);
	}
	nav :checked ~ .icon-burger .line:nth-child(3) {
		transform: translateY(-10px) rotate(-225deg);
	}
	nav :checked ~ .icon-burger .line:nth-child(2) {
		opacity: 0;
	}
	
}
@media screen and (max-height: 374px) {
  nav .links a {
    line-height: 60px;
  }
}
@media screen and (max-height: 299px) {
  nav .links a {
    line-height: 50px;
  }
}